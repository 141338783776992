import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockText from "./block-text";
import BlockContent from "./block-content";
import * as styles from "./category-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

function CategoryPreview(props) {
  const { title, slug, id,mainImage, _rawBody } = props;
  return (
    <Link key={`${id}link`} className={styles.root} to={`/kategoriak/${slug.current}`}>
      <h3 key={`${id}h3`} className={cn(responsiveTitle3, styles.title)}>
        {title}
      </h3>
      <div key={`${id}div`} className={styles.leadMediaThumb}>
        {mainImage && mainImage.asset && (
          <img
            key={`${id}img`}
            src={imageUrlFor(buildImageObj(mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={mainImage.alt}
          />
        )}
      </div>
      {_rawBody && (
        <div key={`${id}div2`} className={styles.excerpt}>
          {props && _rawBody && <BlockContent id={id} blocks={_rawBody || []} />}
        </div>
      )}
    </Link>
  );
}

export default CategoryPreview;
