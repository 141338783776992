import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import CategoryPreviewGrid from "../components/category-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { getCategories } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query CategoriesPageQuery {
    categories: allSanityCategory {
      edges {
        node {
          title
          _rawBody
          slug {
            current
          }
          mainImage {
            asset {
              _id
            }
            alt
          }
        }
      }
    }
  }
`;

const CategoriesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const projectNodes = data && data.categories && getCategories(data.categories);
  return (
    <Layout>
      <SEO title="Kategóriák" />
      <Container>
        <h1 className={responsiveTitle1}>Kategóriák</h1>
        {projectNodes && projectNodes.length > 0 && <CategoryPreviewGrid nodes={projectNodes} />}
      </Container>
    </Layout>
  );
};

export default CategoriesPage;
